<template>
  <ui-dialog title="Anmelde Setup erstellen" ref="dialog" @confirm="confirm()" :confirm-callback="confirmCallback" size="wide" confirmLabel="Erstellen">
    <ui-field label="Abonnentenlisten ID">
      <ui-form-input v-model="$store.state.event.mwSubscriberListId" :readonly="true"/>
    </ui-field>
    <ui-field label="Mailing für Bestätigung">
      <ui-form-dropdown :options="mailOptions" v-model="mailId"/>
    </ui-field>
  </ui-dialog>
</template>

<script>

export default {
  name: "NewSession",
  data(){
    return {
      mailId: undefined
    }
  },
  mounted(){

  },
  computed: {
    mailOptions(){
      return this.$store.state.mailings.filter(m => m.type === 'DIALOG').map(a => {
        return {
          value: a.id,
          label: a.subject + '(' + a.id + ')'
        }
      })
    }
  },
  methods: {
    async confirmCallback() {
      await this.$store.createOptinSetup(
          this.mailId,
          this.$store.state.event.mwSubscriberListId
      )
    },
    async confirm(){
    },
    open(){
      this.$refs.dialog.open()
    }
  }
}
</script>

<style scoped>

</style>
