<template>
  <div>
    <BackCrumb />
    <h1 class="c-view-h1">{{$store.state.event.name}}</h1>
    <ui-toolbar :actions="toolbar"></ui-toolbar>
    <ui-cards v-if="filteredMailings.length > 0">
      <ui-card v-for="(mailing, index) in filteredMailings" :key="index">
        <template v-slot:head>{{mailing.subject}}</template>
        <p>
          ID: {{mailing.id}}
        </p>
        {{mailing.description}}
        <template v-slot:foot><a class="p-2" target="_blank" :href="editLink(mailing.id)" :title="'In '+$env.whiteLabel.mailProduct+' bearbeiten'"><i class="fal fa-edit"></i></a></template>
      </ui-card>
    </ui-cards>
    <div class="no-mailings-info" v-else>
      <span>Hier ist noch nichts.</span>
      <span>Fügen Sie dieser Veranstaltung Mailings hinzu, indem Sie das Tag der Veranstaltung oder die Beschreibung der Mailings anpassen.</span>
    </div>
  </div>
  <ui-alert ref="lpLink">
    Der Link zur Landingpage ist:<br>
    <textarea class="form-textarea mt-1 block w-full" rows="3" disabled v-model="mailingLink"></textarea>

  </ui-alert>
  <new-optin-setup ref="optinsetup" />
</template>

<script>

import {getSID} from "@/store/auth";
import BackCrumb from "../../../components/Admin/Event/BackCrumb";
import NewOptinSetup from "@/components/Admin/dialogs/NewOptinSetup";

export default {
  name: "Mailings",
  components: { BackCrumb, NewOptinSetup },
  data(){
    return {
      filter: true,
      toolbar: [
        {
          title: 'Link zur Landingpage anzeigen',
          label: `<i class="fal fa-link"></i> Link zur Landingpage`,
          component: 'button',
          type: 'blank',
          action: () => { this.$refs.lpLink.open() }
        },
        {
          title: 'Anmeldesetup erstellen',
          label: `<i class="fal tools"></i> Anmeldesetup erstellen`,
          component: 'button',
          type: 'blank',
          isVisible: () => { return (typeof this.$store.state.event.mwOptInSetupId !== 'number') },
          action: () => { this.$refs.optinsetup.open(); }
        },
        {
          title: 'Anmeldesetup bearbeiten',
          label: `<i class="fal tools"></i> Anmeldesetup bearbeiten`,
          component: 'button',
          type: 'blank',
          isVisible: () => { return (typeof this.$store.state.event.mwOptInSetupId === 'number') },
          action: () => { window.open(this.$env.whiteLabel.mailDomain + 'optin/optinsetup/getUpdate/' + this.$store.state.event.mwOptInSetupId + '?sid='+ getSID()) }
        },
        {
          title: 'Alle Mailings anzeigen',
          label: `<i class="fal fa-asterisk"></i> Alle Mailings anzeigen`,
          component: 'button',
          type: 'blank',
          isVisible: () => { return this.filter },
          action: () => { this.filter = false; }
        },
        {
          title: 'Mailings nach Veranstaltung filtern',
          label: `<i class="fal fa-envelope"></i> Nur "${this.$store.state.event.slug}"-Mailings anzeigen`,
          component: 'button',
          type: 'blank',
          isVisible: () => { return !this.filter },
          action: () => { this.filter = true; }
        }
      ]
    }
  },
  created() {
    this.$store.getMailings()
    this.$store.getLandingpages()
  },
  computed: {
    filteredMailings(){
      const ret = this.$store.state.mailings
      if(this.filter === false) return ret

      const patt = new RegExp(this.$store.state.event.slug, "i")
      return ret.filter(entry => patt.test(entry.description))
    },
    mailingLink(){
      return `${this.$env.whiteLabel.publicDomain}lp/${this.$store.state.landingpage.id}/[field property='subscriber-id'/]/[field property='code'/]`
    }
  },
  methods: {
    action(){
      console.log('action')
    },
    editLink(id){
      if(typeof id !== 'undefined'){
        return this.$env.whiteLabel.mailDomain + 'email/Email/getUpdate/'+ id +'?sid='+ getSID()
      }
      return '#'
    }
  }
}
</script>

<style scoped>
button > i {
  margin-right: 6px;
}

.no-mailings-info {
  @apply text-gray-600 text-sm flex flex-col content-center justify-center py-2;
}

.no-mailings-info span {
  @apply text-center;
}
</style>
